@import "src/styles/variables";
@import "src/styles/typography";

.bpla_kit_button {
  display: inline-flex;

  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  border: 0;
  white-space: nowrap;

  svg {
    height: 100%;
  }

  &.fullWidth {
    width: 100%;
  }

  padding: 10px 32px;

  > svg {
    width: 24px;
    height: 24px;
  }

  &--iconOnly {
    padding: 10px;
  }

  &__text {
    @include label-large;
  }

  @media screen and (max-width: 1366px) {
    padding: 7px 24px;

    > svg {
      width: 16px;
      height: 16px;
    }

    &--iconOnly {
      padding: 11px;
    }
  }

  &.primary {
    background-color: $text-accent;
    color: white;

    &:hover {
      background-color: $main-color-hover;
    }
    &:active {
      background-color: $main-color-press;
    }
    &.disabled, &.disabled:active, &.disabled:hover {
      background-color: $main-color-disabled;
      cursor: not-allowed;

      svg, path {
        fill: $icon-disabled-inverse;
      }
    }

    svg, path {
      fill: white;
    }
  }
  &.secondary {
    background-color: $secondary-bg;
    color: $text-accent;

    svg, path {
      fill: $icon-accent;
    }

    &:hover {
      background-color: $secondary-bg-hover;
    }
    &:active {
      background-color: $secondary-bg-press;
    }
    &.disabled, &.disabled:active, &.disabled:hover {
      background-color: $secondary-bg-disabled;
      color: #191D341F;
      cursor: not-allowed;

      svg, path {
        fill: $icon-disabled;
      }
    }
  }
  &.outline {
    background-color: $secondary-bg;
    border: solid 1px $border-accent;
    color: $text-accent;

    svg, path {
      fill: $icon-accent;
    }

    &:hover {
      background-color: $secondary-bg-hover;
    }
    &:active {
      background-color: $secondary-bg-press;
    }
    &.disabled, &.disabled:active, &.disabled:hover {
      background-color: $secondary-bg-disabled;
      color: #191D341F;
      border-color: #191D341F;
      cursor: not-allowed;

      svg, path {
        fill: $icon-disabled;
      }
    }
  }

  &.sizeL {
    @include label-large;

    padding: 10px 32px;

    > svg {
      width: 24px;
      height: 24px;
    }

    &--iconOnly {
      padding: 10px;
    }
  }
  &.sizeM {
    @include label-medium;

    padding: 9px 32px;

    > svg {
      width: 16px;
      height: 16px;
    }

    &--iconOnly {
      padding: 11px;
    }
  }
  &.sizeS {
    @include label-small;

    border-radius: 6px;
    padding: 6px 24px;

    > svg {
      width: 16px;
      height: 16px;
    }

    &--iconOnly {
      padding: 8px;
    }
  }
  &.sizeXS {
    padding: 4px;

    > svg {
      width: 16px;
      height: 16px;
    }
  }
}
