.footer {
  width: 100vw;
  height: auto;
  padding: 40px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 40px 0 20px 0;
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: solid 1px #EAECF0;
    width: calc(100% - 80px);

    @media (max-width: 767px) {
      width: calc(100% - 32px);
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__name {
      display: flex;
      flex-direction: column;

      svg {
        height: 42px;
      }
      p {
        font-size: 14px;
        font-weight: 300;
        color: #3B372DE0;
        max-width: 431px;
        width: inherit;
        margin-bottom: 0;
        margin-top: 16px;
      }
    }

    &__contact {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;

      p {
        font-size: 16px;
        font-weight: 300;
        color: #3B372DE0;

        &:last-child {
          margin-bottom: 0;
        }

        a, a:hover, a:active, a:visited {
          cursor: pointer;
          color: #3B372DE0;
          text-decoration: none;
        }
      }
      &__header {
        font-weight: 400 !important;
        color: #3B372D !important;
      }
    }
  }

  &__rights {
    color: #3B372DA6;
    font-weight: 300;
    font-size: 16px;
    width: calc(100% - 80px);
    padding-top: 20px;
    border-top: solid 1px #EAECF0;

    @media (max-width: 767px) {
      width: calc(100% - 32px);
    }
  }
}