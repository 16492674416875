@import "./styles/variables.scss";
@import "./styles/typography.scss";

body,
h1,
h2,
h3,
p {
  margin: 0;
}

body, .App {
  font-family: $font-family-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

.list {
  display: inline-flex;
  gap: 5px;
}

.m-1 {
  margin: 5px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  row-gap: 10px;

  &.wrap {
    flex-wrap: wrap;
  }
  &.wrapMobile {
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__start {
    justify-content: flex-start;
    column-gap: 8px;

    > * {
      margin-right: 8px;
    }
  }
  &.top {
    align-items: flex-start;
  }
  &.max-w-1200 {
    max-width: 1200px;
  }
}

.page {
  display: flex;
  flex-direction: column;

  &.center {
    align-items: center;
    justify-content: center;
  }

  gap: 32px;
  height: 100%;
  min-height: 100vh;
  padding: 32px;

  box-sizing: border-box;
  background: linear-gradient(0deg, $secondary-bg-subduet 0%, $secondary-bg-subduet 100%, white);

  @media screen and (max-width: 1366px) {
    padding: 24px 16px;
  }

  header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 767px) {
      width: calc(100vw - 32px);
    }

    h1 {
      @include headline-medium;
      color: $text-primary;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;

    align-self: flex-start;
    max-width: 792px;
    width: 100%;

    margin: 24px 40px;
    gap: 24px;

    @media screen and (max-width: 1920px) {
      margin: 24px 38px;
    }
    @media screen and (max-width: 1366px) {
      margin: 16px;
      gap: 16px;
    }
    @media (max-width: 767px) {
      margin: 0 0 0 0;
      //align-items: center;
      //justify-content: center;
    }
  }

  &__card-fields {
    display: flex;
    flex-direction: column;

    padding: 32px;
    gap: 32px;

    border-radius: 12px;
    border: 1px solid $border-primary-subduet;
    background: white;
    box-shadow: 0 0 6px 0 $box-shadow;

    @media screen and (max-width: 1366px) {
      gap: 20px;
    }
    @media (max-width: 767px) {
      max-width: calc(100vw - 32px);
      //margin-right: 16px;
    }

    .page__fields {
      .row {
        align-items: flex-start;

        button {
          margin-top: 26px;
          margin-left: 10px;
        }
      }
    }
  }

  &__submit {
    display: inline-flex;
    gap: 12px;

    @media screen and (max-width: 1366px) {
      display: none;
    }
  }

  &__submit-mobile {
    display: none;

    @media screen and (max-width: 1366px) {
      display: inline-flex;
    }
  }

  .greenText {
    color: $text-link;
  }

  &__between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    &.wrap {
      flex-wrap: wrap;
    }
  }

  table {
    width: 100%;
    border-radius: 12px;
    border-spacing: 0;
    border-collapse: separate;

    thead {
      padding: 12px 24px 12px 24px;
      background-color: #3B372D0A;
      border: solid 1px #3B372D0F;
      border-radius: 12px;

      th {
        color: #3B372DA6;
        font-size: 16px;
        font-weight: 400;
        height: 48px;
        text-align: left;
        padding: 0px 24px 0px 24px;

        @media (max-width: 767px) {
          font-size: 13px;
          padding: 0px 16px 0px 16px;
        }

        &:first-child {
          border-top-left-radius: 12px;
        }

        &:last-child {
          border-top-right-radius: 12px;
        }
      }
    }

    tbody {
      tr {
        padding: 0px 24px 0px 24px;
        height: 64px;
        background-color: #ffffff;
        border-left: solid 1px #3B372D0F;
        border-bottom: solid 1px #3B372D0F;
        border-right: solid 1px #3B372D0F;
        border-radius: 12px;

        @media (max-width: 767px) {
          padding: 0px 16px 0px 16px;
        }

        &:hover {
          background-color: #3B372D08;
          cursor: pointer;
        }

        td {
          padding: 0 24px;
          font-weight: 400;
          font-size: 16px;
          color: #3B372DE0;

          @media (max-width: 767px) {
            font-size: 13px;
            padding: 0px 5px;
          }

          a, a:hover, a:active, a:visited {
            color: $text-link;
          }

          .bpla_kit_status_application__edit {
            background-color: #ffffff;
            z-index: 1000;

            &:hover {
              background-color: #ffffff;
            }
          }
        }

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;

          > td:first-child {
            border-bottom-left-radius: 12px;
          }

          > td:last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }

  button.bpla_kit_button {
    width: fit-content;
  }

  img.bplaImage {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 794px;
    height: 324px;

    @media (max-width: 767px) {
      width: 328px;
      height: 134px;
    }
  }
}

.leaflet-bottom.leaflet-right {
  display: none;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}


.publicPage {
  &__menuPadding {
    height: 80px;
    width: 100vw;
  }

  &__promo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 64px;
    width: 100vw;
    background-color: $secondary-bg-blue-subduet-hover;
    padding: 96px 0 0 0;
    max-height: 891px;
    height: auto;
    overflow: hidden;

    @media (max-width: 767px) {
      padding: 48px 0 0 0;
      gap: 16px;
    }

    h1 {
      color: #2D2A22;
      font-size: 54px;
      font-weight: 500;
      margin-bottom: 0;
      padding: 0 16px;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 32px;
      }
    }

    p {
      color: #3B372DE5;
      font-size: 20px;
      font-weight: 300;
      max-width: 650px;
      text-align: center;
      margin-bottom: 0;
      padding: 0 16px;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    svg {
      width: 100%;
      max-height: calc(100vw - 160px);
      height: auto;

      @media (max-width: 767px) {
        max-height: calc(100vw - 30px);
      }
    }
  }

  &__articleList {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: calc(100vw - 160px);
    gap: 32px;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      gap: 16px;
      width: calc(100vw - 80px);
    }

    > div {
      width: calc(100vw - 224px);
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 32px;
      flex-wrap: wrap;

      @media (max-width: 767px) {
        width: calc(100vw - 144px);
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: calc((100vw - 224px) * .3);
      gap: 8px;
      cursor: pointer;
      border-radius: 5px;

      @media (max-width: 767px) {
        width: calc(100vw - 144px);
      }

      &:hover {
        background: radial-gradient(#AAAAAA22, #ffffff00);
      }

      > img {
        margin-bottom: 24px;
        width: inherit;
        border-radius: 5px;
        height: auto;
        max-height: 240px;
        align-self: center;
      }
    }
  }

  &__article {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      margin-bottom: 30px;
      margin-left: 80px;
      margin-right: 80px;
      flex: 1;
      width: calc(100vw - 80px);
      gap: 8px;

      h1 {
        margin-bottom: 64px;
      }

      img {
        width: inherit;
        height: auto;
        max-height: 516px;
        margin-bottom: 64px;
        object-fit: contain;
      }

      p.text {
        max-width: 720px;
      }
    }
  }

  &__indexArticles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;

    &__header {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 50px;
      margin-bottom: 30px;
      margin-left: 80px;
      margin-right: 80px;
      flex: 1;
      width: calc(100vw - 80px);

      h2 {
        font-size: 26px;
        font-weight: 500;

        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
    }

    &__grid {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 50px;
      flex: 1;
      width: calc(100vw - 80px);

      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: calc(100vw - 20px);
      }

      > div {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @media (max-width: 767px) {
          justify-content: center;
          align-items: center;
        }

        &:last-child {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 30px;
        }

        img {
          width: inherit;
          height: auto;
          max-height: 200px;
          align-self: center;
          border-radius: 5px;
        }
      }

      &__lineBlock {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 24px;

        > div {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          &:last-child {
            @media (max-width: 767px) {
              flex: 2;
            }
          }

          img {
            max-height: 100px;
            @media (max-width: 767px) {
              height: 100px;
              width: 100px;
              object-fit: contain;
            }
          }
        }
      }
    }
    &__article {
      p {
        width: 100%;
        max-width: 40vw;
        text-align: left;
      }

      &__date {
        color: #0AA257;
        font-size: 14px;
        font-weight: 400;
      }
      &__title {
        color: #3B372D;
        font-size: 20px;
        font-weight: 500;
      }
      &__content {
        color: #3B372DE0;
        font-size: 16px;
        font-weight: 300;
        text-overflow: ellipsis;
      }
    }
  }
}

.img200px {
  width: 200px;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 15px;
}

.blueText {
  background-color: $bg-blue;
  padding: 2px 10px;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;

  > span {
    margin-right: 10px;
    width: 25px;
    height: 25px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-blue;
    color: #FFFFFF;
  }
}

.greenText {
  background-color: $bg-green;
  padding: 2px 10px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-left: 10px;
  color: $text-accent;
  font-size: 14px;
}

.chatManager {
  color: #3B372DE0;
  font-size: 14px;
  text-align: left;

  > span {
    font-size: 13px;
    color: #3B372DA6;
  }
}

.blueText {
  color: $main-blue;
  font-size: 13px;
}

.w-100 {
  width: 100% !important;
}

hr {
  color: #3B372D0F;
  height: 0;
}

.littleGreyText {
  color: #3B372DA6;
  font-size: 11px;
  font-weight: 300;
  line-height: 13px;
  margin-top: 7px;
}

.d-none {
  display: none;
}

.littleBtn {
  padding: 6px 12px;

  .bpla_kit_button {
    padding: 0;
  }

  span {
    font-size: 13px !important;
  }
  svg {
    width: 16px;
    height: 16px;
  }
}

.mapContainer {
  width: 450px;
  height: 450px;

  @media (max-width: 767px) {
    width: calc(100vw - 40px);
  }
}