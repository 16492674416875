@import "src/styles/variables";

.inline-loader {
  position: relative;

  display: inline-block;
  width: 24px;
  height: 24px;

  > div {
    position: absolute;

    display: block;

    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    box-sizing: border-box;
    margin: 0;
    border-width: 0.125em;
    border-style: solid;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  > div:nth-child(1) {
    animation-delay: -0.45s;
  }

  > div:nth-child(2) {
    animation-delay: -0.3s;
  }

  > div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &.primary {
    > div {
      border-color: white transparent transparent transparent;
    }
  }
  &.secondary {
    > div {
      border-color: $icon-accent transparent transparent transparent;
    }
  }
  &.outline {
    > div {
      border-color: $icon-accent transparent transparent transparent;
    }
  }

  &.sizeL {
    width: 24px;
    height: 24px;
  }
  &.sizeM {
    width: 22px;
    height: 22px;
    &--iconOnly {
      width: 16px;
      height: 16px;
    }
  }
  &.sizeS {
    width: 20px;
    height: 20px;
    &--iconOnly {
      width: 16px;
      height: 16px;
    }
  }
  &.sizeXS {
    width: 16px;
    height: 16px;
    &--iconOnly {
      width: 16px;
      height: 16px;
    }
  }
}
