$main-blue: #308DE3;
$main-color-hover: #099651;
$main-color-press: #038746;
$main-color-disabled: #0AA25766;

$secondary-bg: #191D3405;
$secondary-bg-hover: #191D340F;
$secondary-bg-press: #0AA25724;
$secondary-bg-disabled: #191D3403;
$secondary-bg-critical: #FF34340D;
$secondary-bg-accent: #0AA257;
$secondary-bg-accent-subduet: #0AA2570D;
$secondary-bg-subduet: #3B372D05;
$secondary-bg-blue-subduet-hover: #2C80CF0F;
$secondary-bg-blue-subduet-active: #2C80CF1A;
$secondary-bg-critical-subduet-active: #D92D201F;
$secondary-bg-caution-subduet-active: #F1BC0014;

$text-link: #0AA257E0;
$text-accent: #0AA257;
$text-accent-hover: #099651;
$text-tertiary: #191D3480;
$text-secondary: #191D34CC;
$text-primary: #191D34;
$text-critical: #FF3434;
$text-caution: #F1BC00;
$text-disabled: #191D341F;

$icon-tertiary: #191D3480;
$icon-accent: #0AA257;
$icon-success: #0AA257;
$icon-accent-hover: #099651;
$icon-link: #0AA257E0;
$icon-logo: #595B63;
$icon-critical: #FF3434;
$icon-caution: #F1BC00;
$icon-disabled: #191D341F;
$icon-disabled-inverse: #FFFFFFE0;

$border-accent: #0AA257E0;
$border-accent-hover-inverse: #FFFFFF66;
$border-critical: #FF3434;
$border-primary: #191D3466;
$border-primary-subduet: #3B372D0F;
$border-disabled: #191D340F;
$border-secondary: #191D341F;
$border-tertiary: #191D340F;

$notification-shadow: #0B2E1D1A;
$block-shadow-hover: #081C0A14;
$box-shadow: #081C0A08;

$bg-caution: #F1BC0066;
$bg-blue: #287CC90D;
$bg-green: #0AA2570D;

$iconGrey: #3B372D;
