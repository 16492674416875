@import "src/styles/variables";
@import "src/styles/typography";

.bpla_kit_nav_bar_public {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 80px;
  top: 0;
  background-color: #FFFFFF;

  &.active {
    height: 224px;
    &__header {
      border-bottom: none;
    }
  }

  &__header {
    height: 80px;
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F2F4F7;

    &__link {
      @include body-medium;
      text-decoration: none;
      color: $text-tertiary;

      background: white;
      border-radius: 8px;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      gap: 12px;
      cursor: pointer;
      margin-right: 20px;

      &:hover {
        background: $secondary-bg-blue-subduet-hover;
      }

      @media screen and (max-width: 1200px) {
        @include body-small;
        padding: 12px 24px;
      }

      &.activeLink {
        background-color: $secondary-bg-press;
      }
    }

    > div {
      margin: 0 40px;

      @media (max-width: 767px) {
        margin: 0 16px;
      }
    }

    svg {
      height: 42px;
      
      @media (max-width: 767px) {
        height: 30px;
        width: 30px;

        &.logo {
          width: 180px;
        }
      }
    }
  }

  &__subMenu {
    border-bottom: 1px solid #F2F4F7;
    align-items: center;
    justify-content: center;

    &__link {
      @include body-medium;
      text-decoration: none;
      color: $text-tertiary;

      display: flex;
      background: white;
      border-radius: 8px;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      height: 48px;
      width: 100%;

      &:hover {
        background: $secondary-bg-blue-subduet-hover;
      }

      @media screen and (max-width: 1200px) {
        @include body-small;
        padding: 0px 24px;
      }
    }
  }

  .mobile {
    display: none;

    @media (max-width: 1200px) {
      display: initial;
    }
  }

  .desktop {
    display: initial;

    @media (max-width: 1200px) {
      display: none;
    }
  }
}