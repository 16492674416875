@import "src/styles/variables";
@import "src/styles/typography";

.bpla_kit_action_link {
  color: $text-link;
  text-decoration: none;
  cursor: pointer;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @include label-large;
  @media screen and (max-width: 1366px) {
    @include label-medium;
  }

  > svg, path {
    max-width: 16px;
    max-height: 16px;
    fill: $icon-link;
  }

  display: inline-flex;
  gap: 4px;
  align-items: center;

  &:hover {
    color: $text-accent-hover;

    > svg, path {
      fill: $icon-accent-hover;
    }
  }

  &.sizeM {
    @include label-large;
  }
  &.sizeS {
    @include label-medium;
  }
}