@import "variables";
@import url("https://fonts.googleapis.com/css?family=Geologica");

$font-family-main: "Geologica", sans-serif;

// Figma name: display/large
@mixin display-large {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 62.4px */
}

// Figma name: display/medium
@mixin display-medium {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 52px */
}

// Figma name: display/small
@mixin display-small {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 46.8px */
}

// Figma name: headline/large
@mixin headline-large {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 41.6px */
}

// Figma name: headline/medium
@mixin headline-medium {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 36.4px */
}

// Figma name: headline/small
@mixin headline-small {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 28.6px */
}

// Figma name: title/large
@mixin title-large {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}

// Figma name: title/medium
@mixin title-medium {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

// Figma name: title/small
@mixin title-small {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

// Figma name: label/large
@mixin label-large {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

// Figma name: label/medium
@mixin label-medium {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

// Figma name: label/small
@mixin label-small {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
}

// Figma name: body/large
@mixin body-large {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 145%; /* 26.1px */
}

// Figma name: body/medium
@mixin body-medium {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 145%; /* 23.2px */
}

// Figma name: body/small
@mixin body-small {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 145%; /* 20.3px */
}

// Figma name: body/xsmall
@mixin body-xsmall {
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 135%; /* 17.55px */
}

// Figma name: link/medium
@mixin link-medium {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 145%;
  text-decoration-line: underline;
}

// Figma name: link/small
@mixin link-small {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 145%;
  text-decoration-line: underline;
}

// Figma name: link/xsmall
@mixin link-xsmall {
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 145%;
  text-decoration-line: underline;
}
